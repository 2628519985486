import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import TwoColumns from '../components/Portfolio/TwoColumns'
import ProjectStartArea from "../components/Index/ProjectStartArea"
import Video from '../components/Portfolio/Video'
import Banner from "../components/Portfolio/Banner"

// Functional component
const Portfolio = () => {
    return (
        <Layout>
            <Navbar /> 
            <Banner />
            <TwoColumns />
            <Video/>
            <ProjectStartArea />
            <Footer />
        </Layout>
    );
}

export default Portfolio