import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'

import starIcon from '../../assets/images/star-icon.png'

const FAQ = () => {
    return (
        <section className="solutions-area pt-70 pb-100 bg-f1f8fb">
            <div className="container">
                <div className="section-title pb-40">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" />
                        Latest project
                    </span>
                    <h2>Mobile App</h2>
                    <p>Take a look at final output & how we solve challenges to meet project requirements. </p>
                </div>
                <div className="video-responsive aligncenter">
                    <iframe
                        width="853"
                        height="480"
                        src={`https://www.youtube.com/embed/8aPIvuldyME`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>
            </div>
        </section>
    );
}

export default FAQ